body,
html,
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cardSize {
  height: 10vw;
  width: 13.3vw;
  margin: 10px;
  cursor: pointer;
}

.cardSizeLarge {
  height: 21vw;
  width: 28vw;
  min-height: 112px;
  min-width: 150px;
  max-height: 225px;
  max-width: 300px;
  margin: 1%;
}

.emptySpot {
  background-color: transparent;
}

.card {
  border-radius: 8px;
}

.animate100 {
  animation-duration: 100ms !important;
}

.animate200 {
  animation-duration: 200ms !important;
}

.animate300 {
  animation-duration: 300ms !important;
}

/* Custom Flip styles */

.customFlip {
  perspective: 1000px;
}

.customFlipInner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.customFlipFront,
.customFlipBack {
  position: absolute;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the back side */
.customFlipBack {
  transform: rotateY(180deg);
}

.customMaxHeight {
  max-height: 90vh;
}

.customContainerHeight {
  height: 95vh;
}

@media (max-width: 1024px) {
  .emptySpot {
  }
  .card {
  }
  .cardSize {
    height: 15vw;
    width: 20vw;
    margin: 5px;
  }
}

@media (max-width: 640px) {
  .customMaxHeight {
    max-height: 96vh;
  }
  .cardSize {
    height: 22.5vw;
    width: 30vw;
    margin: 5px;
  }
}

/* Loading Styles */
/* Found here: https://loading.io/css/ */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
